import { Injectable } from '@angular/core';

import { RestService } from 'src/app/core/services/rest.service';
import { AppService } from 'src/app/core/services/app.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { WEBINAR_ATTENDEE_EVENTS } from '../constants/webinar-attendee-events';
import { EventEmitterService } from 'src/app/core/services/event-emitter.service';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { GuestAuthService, LocalStorageService } from 'src/app/core';
import { AppLoggerService } from 'src/app/core/services/app-logger.service';
import { differenceInMinutes } from 'date-fns';
import { APP_CONSTANT } from 'src/app/constants';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AttendeeRcsService } from '../services';

@Injectable({
    providedIn: 'root'
})
export class WebinarAttendeeService {
    private isAuthenticated;
    private webinarInfo$;
    private webinarInfoDecoded;
    private broadcastStatus$: Subject<any> = new Subject();
    private invalidMeetingSubject = new BehaviorSubject<boolean>(false);
    invalidMeeting$ = this.invalidMeetingSubject.asObservable();
    headers: HttpHeaders = new HttpHeaders();
    reaction$ = new BehaviorSubject({ type: 'like', state: false });
    isGuestUser: boolean = false;
    isIFrameAutoJoinAttendee: boolean = false;
    reactionsUpdate = new BehaviorSubject({});
    reactionDetails: any = {};
    setTabToHome: boolean = false;
    platinumSponsorLogos: any = [];
    goldSponsorLogos: any = [];
    silverSponsorLogos: any = [];
    sponsorsLogos: any = [];
    customCategoryNames: any = [];
    streamUrl;
    isBroadcasted: boolean = false;
    isMyJio;
    isEmbedInMobile = false;
    guestUser;
    commentsText: any = [];
    private isPreRecordedEvent: boolean = false;
    isPreRecordedEventStarted: any = new BehaviorSubject(false);
    pollingEventData$ = new Subject();
    autoJoinInProgress = false;
    isInviteOnlyEvent: boolean = false;
    guestUserDetails: any;
    equalTileLayout = false;
    constructor(
        private restService: RestService,
        private authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private eventEmitterService: EventEmitterService,
        private sessionStorageService: SessionStorageService,
        private appService: AppService,
        private localStorageService: LocalStorageService,
        private appLoggerService: AppLoggerService,
        private guestAuthService: GuestAuthService,
        private roomConnectionService: AttendeeRcsService
    ) {
        this.authService.getIsAuthenticated$().subscribe((bool) => {
            this.isAuthenticated = bool;
        });
        this.activatedRoute.queryParams.subscribe((params) => {
            this.isMyJio = params?.isMyJio ? true : false;
            this.isEmbedInMobile = params?.isEmbedInMobile ? true : false;
        });
    }

    setIsInvalidMeeting(isInvalid: boolean) {
        this.invalidMeetingSubject.next(isInvalid);
    }

    setTabForCancelledEvent(homeTab: boolean) {
        this.setTabToHome = homeTab;
    }
    getTabForCancelledEvent() {
        return this.setTabToHome;
    }

    setStreamUrl(url) {
        this.streamUrl = url;
    }

    getStreamUrl() {
        return this.streamUrl;
    }

    isBroadcasting(webinarId) {
        return this.restService
            .get(`${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/isbroadcasting`)
            .pipe(
                tap((res) => {
                    this.emitBroadcastStatus(res);
                })
            );
    }

    startExternalRecording(webinarId) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/startrecording`,
            {}
        );
    }

    stopExternalRecording(webinarId) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/stoprecording`,
            {}
        );
    }

    stopExternalRecordingByRecorder(webinarId, hash, signature) {
        this.headers = this.headers.set('webhook-signature', signature);
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/recorder/stoprecording`,
            {
                hash
            },
            { headers: this.headers }
        );
    }

    emitBroadcastStatus(status) {
        this.isBroadcasted = status?.broadcasting;
        this.broadcastStatus$.next(status);
    }

    getBroadcastInfo() {
        return this.broadcastStatus$;
    }

    getIsBroadcasting() {
        return this.isBroadcasted;
    }

    streamWebinarUrl(webinarId) {
        return this.restService
            .get(
                `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/streamurl${
                    this.isUserAuthenticated() ? '' : '/guest'
                }` + (this.isMyJio ? '?source=myjio' : ''),
                { headers: this.headers }
            )
            .pipe(
                tap((res) => {
                    if (res.url) {
                        this.setStreamUrl(res.url);
                    }
                })
            );
    }

    streamInUrl(channelId) {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/rtmp/${channelId}/getStreamUrl`, {
            headers: { Authorization: this.guestAuthService.getAuthInfo().jwt }
        });
    }

    streamInUrlAttendee(channelId) {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/rtmp/${channelId}/getStreamUrl`);
    }

    getEventReplayDetails(webinarId) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/attendees/eventReplayDetails`
        );
    }

    getRegistrationOtp(webinarId, body) {
        return this.restService.post(`/api/meeting/${webinarId}/webinar/register/getotp`, body);
    }

    validateOtp(webinarId, body) {
        return this.restService.post(`/api/meeting/${webinarId}/webinar/register/verifyotp`, body);
    }

    registerForWebinar(
        webinarId,
        hash,
        firstName,
        lastName,
        emailId,
        phoneNo,
        additionalDetails,
        sessionId,
        sessionMeetingId
    ) {
        const payload: any = {
            hash,
            firstName,
            lastName,
            additionalDetails,
            ...(phoneNo && { phoneNo }),
            ...(emailId && { emailId }),
            sessionId,
            sessionMeetingId
        };

        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/${
                this.isUserAuthenticated() ? 'userRegistration' : 'register'
            }` + (this.isMyJio ? '?source=myjio' : ''),
            payload
        );
    }

    getSession(webinarId) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/sessions/${webinarId}/attendee/sessionsdetails`
        );
    }

    getBrand(webinarId) {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/brand`);
    }

    getWebinarDetailsForRegistration(webinarId, hash) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/details`,
            { hash }
        );
    }
    getSponsorsDetails(webinarId) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/sponsors`
        );
    }
    toggleLikeStatus(webinarId, type) {
        return this.restService
            .post(
                `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/attendee/like${
                    this.isUserAuthenticated() ? '' : '/guest'
                }`,
                {
                    type: type
                },
                { headers: this.headers }
            )
            .pipe(tap(() => this.reaction$.next({ type, state: true })));
    }

    toggleUnlikeStatus(webinarId, type) {
        return this.restService
            .post(
                `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/attendee/unlike${
                    this.isUserAuthenticated() ? '' : '/guest'
                }`,
                {},
                { headers: this.headers }
            )
            .pipe(tap(() => this.reaction$.next({ type, state: false })));
    }

    sendPollingdata(res) {
        this.pollingEventData$.next(res);
    }

    getPollingdata() {
        return this.pollingEventData$;
    }

    toggleRaiseHandStatus(webinarId) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/attendee/handraise${
                this.isUserAuthenticated() ? '' : '/guest'
            }`,
            { headers: this.headers }
        );
    }

    toggleLowerHandStatus(webinarId) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/attendee/lowerhand${
                this.isUserAuthenticated() ? '' : '/guest'
            }`,
            { headers: this.headers }
        );
    }

    askQuestion(webinarId, title, target) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/attendee/qnas${
                this.isUserAuthenticated() ? '' : '/guest'
            }`,
            { title, target },
            { headers: this.headers }
        );
    }

    getQuestions(webinarId, { offset = 0, limit }) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/attendee/qnas${
                this.isUserAuthenticated() ? '' : '/guest'
            }?offset=${offset}&limit=${limit}`,
            { headers: this.headers }
        );
    }

    joinAttendeeDetails(webinarId) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/attendees/joindetails`
        );
    }
    joinAttendeeAsGuest(
        webinarId,
        { firstName = '', lastName = '', hash = '', emailId = '', phoneNo = '', userId = '' }
    ) {
        const payload: any = {
            ...(firstName && { firstName }),
            ...(lastName && { lastName }),
            ...(emailId && { emailId }),
            ...(phoneNo && { phoneNo }),
            ...(hash && { hash }),
            ...(userId && { userId })
        };
        this.appLoggerService.setGuestUserInfo(payload);
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/attendees/joinasguest` +
                (this.isMyJio ? '?source=myjio' : this.isEmbedInMobile ? '?source=jiomeet' : ''),
            payload
        );
    }

    joinAsLoggedIn(webinarId) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/attendees/joinloggedinuser` +
                (this.isMyJio ? '?source=myjio' : this.isEmbedInMobile ? '?source=jiomeet' : ''),
            {}
        );
    }

    setAuthInfoForGuest(webinarId, token) {
        if (!token || !token?.jwt) {
            this.webinarInfo$ = null;
            return;
        }
        this.sessionStorageService.addItem(webinarId, { jwt: token, sessionInProgress: true });
        this.webinarInfo$ = token;
        this.webinarInfoDecoded = this.parseJwt(token.jwt);
        this.headers = this.headers.set('Authorization', this.webinarInfo$.jwt);
        this.eventEmitterService.emit({
            type: WEBINAR_ATTENDEE_EVENTS.GUEST_ATTENDEE_AUTH_INFO,
            data: null
        });
        this.isGuestUser = true;
    }

    setIsIFrameAutoJoinAttendee(isIFrameAutoJoinAttendee) {
        this.isIFrameAutoJoinAttendee = isIFrameAutoJoinAttendee;
    }

    getIsIFrameAutoJoinAttendee() {
        return this.isIFrameAutoJoinAttendee;
    }

    getGuestAttendeeToken() {
        return this.webinarInfo$;
    }

    getGuestAttendeeDetails() {
        return this.webinarInfo$;
    }

    setGuestInfo(user) {
        this.guestUser = user;
    }

    getGuestUser() {
        return this.guestUser;
    }

    parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(
            window
                .atob(base64)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join('')
        );

        return JSON.parse(jsonPayload);
    }

    getUserId() {
        if (!this.webinarInfoDecoded && this.webinarInfo$?.jwt) {
            this.webinarInfoDecoded = this.parseJwt(this.webinarInfo$.jwt);
        }
        return this.webinarInfoDecoded?.userId;
    }

    getCommentsForAttendee(webinarId, { offset = 0, limit }) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/attendee/comments${
                this.isUserAuthenticated() ? '' : '/guest'
            }?offset=${offset}&limit=${limit}`,
            { headers: this.headers }
        );
    }

    postCommentForAttendee(webinarId, content) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/attendee/comments${
                this.isUserAuthenticated() ? '' : '/guest'
            }`,
            {
                components: [
                    {
                        content,
                        type: 'text'
                    }
                ],
                messageType: 'text'
            },
            { headers: this.headers }
        );
    }

    getUserLikeStatus(webinarId) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/attendee/hasliked${
                this.isUserAuthenticated() ? '' : '/guest'
            }`,
            { headers: this.headers }
        );
    }

    async getGuestUserDetailsIfRequired() {
        if (!this.guestUserDetails && !this.isUserAuthenticated()) {
            try {
                this.guestUserDetails = await this.getGuestUserDetails().toPromise();
            } catch (err) {
                console.log(err);
            }
        }
    }

    getGuestUserDetails() {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/addons/getUserInfoForToken${
                this.isUserAuthenticated() ? '' : '/guest'
            }`,
            { headers: this.headers }
        );
    }

    submitFeedBackForAttendee(webinarId, payload) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/attendee/feedback${
                this.isUserAuthenticated() ? '' : '/guest'
            }` + (this.isMyJio ? '?source=myjio' : ''),
            payload,
            { headers: this.headers }
        );
    }

    leaveWebinarForAttendee(webinarId) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/attendee/leave${
                this.isUserAuthenticated() ? '' : '/guest'
            }`,
            {},
            { headers: this.headers }
        );
    }

    leaveWebinarForGuestAttendee(webinarId, guestInfo) {
        this.webinarInfo$ = { jwt: guestInfo.guestAttendeeToken };
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/attendee/leave/guest`,
            {},
            { headers: this.headers }
        );
    }

    getWebinarOptionsForAttendee(webinarId) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/attendees/webinaroptions${
                this.isUserAuthenticated() ? '' : '/guest'
            }`,
            { headers: this.headers }
        );
    }

    getLikeDetailsForAttendee(webinarId) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/attendee/likesdetails${
                this.isUserAuthenticated() ? '' : '/guest'
            }`,
            { headers: this.headers }
        );
    }

    hasUserRegistered(webinarId, hash) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/hasRegistered`,
            { hash }
        );
    }

    isUserInWebinar(webinarId, hash) {
        return this.restService.get(
            `${this.appService.getEnvVariable(
                'BASE_URL'
            )}/meeting/${webinarId}/webinar/attendees/inWebinar?joinHash=${hash}`
        );
    }

    hasUserRegisteredLoggedIn(webinarId, hash) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/hasRegistered/loggedin`,
            { hash }
        );
    }

    updateSessionsToRemind(webinarId, hash, sessionInfo) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/sessionReminders`,
            { hash, sessions: [sessionInfo] }
        );
    }

    checkAttendeeHash(webinarId, hash) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/attendeeHash`,
            { hash }
        );
    }

    getRoomInfoForAttendee(webinarId) {
        return this.restService
            .get(
                `${this.appService.getEnvVariable(
                    'BASE_URL'
                )}/meeting/${webinarId}/webinar/attendee/roomConnectionStatus/get${
                    this.isUserAuthenticated() ? '' : '/guest'
                } `,
                { headers: this.headers }
            )
            .pipe(tap((response) => this.roomConnectionService.setRoomInfo(response)));
    }

    getPollingEventData(webinarId) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/status/updates${
                this.isUserAuthenticated() ? '' : '/guest'
            }`,
            { headers: this.headers }
        );
    }

    getSpeakerLink(webinarId) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/speakerlink${
                this.isUserAuthenticated() ? '' : '/guest'
            }`,
            { headers: this.headers }
        );
    }

    updateRegistrationInfo(hash, webinarId) {
        try {
            if (this.appService.getConfigVariableWithDefault('STORE_JOINING_HASH')) {
                this.localStorageService.addItem(webinarId, hash);
            }
        } catch (err) {
            console.log(err, 'error updating registration info');
        }
    }
    handleHashRedirection(hash, webinarId, url = '') {
        try {
            const updatedHash = this.localStorageService.getItem(webinarId);
            if (updatedHash && hash !== updatedHash) {
                window.location.href = `${window.location.href.replace(hash, updatedHash)}`;
            } else {
                //invalid hash scenario
                if (hash !== url.split('/').pop()) {
                    this.cleanupRegistrationInfo(webinarId);
                    window.location.href = url;
                }
            }
        } catch (err) {
            console.log(err, 'error handling hash redirection');
        }
    }

    isUserAuthenticated() {
        return this.isAuthenticated && !this.isGuestUser ? true : false;
    }

    cleanupRegistrationInfo(webinarId) {
        try {
            this.localStorageService.removeItem(webinarId);
        } catch (err) {
            console.log(err, 'error cleaning up registration info');
        }
    }

    cleanup(webinarId) {
        this.webinarInfo$ = null;
        this.sessionStorageService.removeItem(webinarId);
        this.cleanupRegistrationInfo(webinarId);
        this.isGuestUser = false;
    }

    buildRedirectUrlWithRtmParameters(sponsor) {
        var url;
        if (sponsor?.redirectUrl.indexOf('http://') == 0) {
            url = sponsor?.redirectUrl.slice('http://'.length);
        } else if (sponsor?.redirectUrl.indexOf('https://') == 0) {
            url = sponsor?.redirectUrl.slice('https://'.length);
        } else {
            url = sponsor?.redirectUrl;
        }
        url =
            'https://' +
            url +
            (_.get(sponsor.utmParameters, 'campaignSource')
                ? '?utm_source=' + _.get(sponsor.utmParameters, 'campaignSource')
                : '') +
            (_.get(sponsor.utmParameters, 'campaignMedium')
                ? '&utm_medium=' + _.get(sponsor.utmParameters, 'campaignMedium')
                : '') +
            (_.get(sponsor.utmParameters, 'campaignName')
                ? '&utm_campaign=' + _.get(sponsor.utmParameters, 'campaignName')
                : '');
        return url;
    }
    getAllSponsorsData(sponsor, category) {
        this.sponsorsLogos.push({
            category: category,
            name: sponsor.name,
            logo: sponsor.logo,
            redirectUrl: this.buildRedirectUrlWithRtmParameters(sponsor)
        });
    }
    getSponsorsData(sponsors) {
        this.platinumSponsorLogos = [];
        this.goldSponsorLogos = [];
        this.silverSponsorLogos = [];
        this.sponsorsLogos = [];
        this.customCategoryNames = [];
        var self = this;
        _.forEach(sponsors, function (category) {
            if (!_.isEmpty(_.get(category, 'sponsors'))) {
                _.forEach(category.sponsors, function (sponsor) {
                    if (sponsor.logo !== '') {
                        if (category.category === 'Platinum') {
                            self.customCategoryNames[0] = category.title || category.category;
                            self.platinumSponsorLogos.push({
                                name: sponsor.name,
                                logo: sponsor.logo,
                                redirectUrl: self.buildRedirectUrlWithRtmParameters(sponsor)
                            });
                            self.getAllSponsorsData(sponsor, self.customCategoryNames[0]);
                        } else if (category.category === 'Gold') {
                            self.customCategoryNames[1] = category.title || category.category;
                            self.goldSponsorLogos.push({
                                name: sponsor.name,
                                logo: sponsor.logo,
                                redirectUrl: self.buildRedirectUrlWithRtmParameters(sponsor)
                            });
                            self.getAllSponsorsData(sponsor, self.customCategoryNames[1]);
                        } else {
                            self.customCategoryNames[2] = category.title || category.category;
                            self.silverSponsorLogos.push({
                                name: sponsor.name,
                                logo: sponsor.logo,
                                redirectUrl: self.buildRedirectUrlWithRtmParameters(sponsor)
                            });
                            self.getAllSponsorsData(sponsor, self.customCategoryNames[2]);
                        }
                    }
                });
            }
        });
        return {
            sponsorsLogos: self.sponsorsLogos,
            platinumSponsorLogos: self.platinumSponsorLogos,
            goldSponsorLogos: self.goldSponsorLogos,
            silverSponsorLogos: self.silverSponsorLogos,
            customCategoryNames: self.customCategoryNames
        };
    }

    getWebinarSessionsInfo(webinarId, joinHash) {
        const baseUrl = this.appService.getEnvVariable('BASE_URL');
        const url = `${baseUrl}/sessions/${webinarId}/attendee/sessionsdetails`;

        // Include hash in the URL as a query parameter
        const fullUrl = `${url}?joinHash=${encodeURIComponent(joinHash)}`;

        return this.restService.get(fullUrl, {
            headers: this.headers
        });
    }
    getSessionDetailsByDateRangeAttendee(webinarId, startTime, endTime, joinHash) {
        const baseUrl = window.location.origin;
        console.log(baseUrl, 'baseurl');
        const url = `${baseUrl}/api/sessions/${webinarId}/attendee/sessionsdetails?from=${startTime}&to=${endTime}`;

        const fullUrl = `${url}&joinHash=${encodeURIComponent(joinHash)}`;

        return this.restService.get(fullUrl, {
            headers: this.headers
        });
    }

    getJoinState(webinarDetails, session, sessionIndex, userHasRegistered) {
        if (webinarDetails?.customization?.customEvent === 'spectrum') return true;
        const defaultJoinEnableTime =
            this.appService.getConfigVariable('ENABLE_ATTENDEE_JOIN_BEFORE_IN_MIN') ||
            APP_CONSTANT.DEFAULT_SESSION_JOIN_ENABLE;
        //get session start time, if it's less then or equal to 5 min then also enable join
        // var isRecurring = webinarDetails?.repeatOptions?.repeat === 'daily';
        // var sessionStartTimeForToday = new Date(session.startTime);
        // if (isRecurring) {
        //     // use end time for the day ?
        //     var today = new Date();
        //     sessionStartTimeForToday.setDate(today.getDate());
        //     sessionStartTimeForToday.setMonth(today.getMonth());
        //     sessionStartTimeForToday.setFullYear(today.getFullYear());
        // }
        const timeRemainingToStartSession = differenceInMinutes(new Date(session.startTime), new Date());
        return (
            (!webinarDetails?.registrationEnabled ||
                (webinarDetails?.registrationEnabled && userHasRegistered) ||
                (webinarDetails?.inviteOnly && webinarDetails?.sessionsEnabled)) &&
            (sessionIndex === 0 || session.broadcasting || timeRemainingToStartSession <= defaultJoinEnableTime - 1)
        );
    }

    setPreRecordEventType(state) {
        this.isPreRecordedEvent = state;
    }

    getPreRecordEventType() {
        return this.isPreRecordedEvent;
    }

    updatePrerecordedEvent(started) {
        this.isPreRecordedEventStarted.next(started);
    }

    getSentimentQuestion(meetingId) {
        return this.restService.get(`/api/addons/sentiment-questions?filters[eventId]=${meetingId}`);
    }

    addSentimentReply(body) {
        return this.restService.post('/api/addons/sentiments?populate=*', body);
    }
    filterSentimentReply(id, meetingId) {
        return this.restService.get(
            `/api/addons/sentiments?populate=*&filters[userId]=${id}&filters[eventId]=${meetingId}`
        );
    }
}
